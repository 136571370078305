import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JOIN_TEAM_NOTIFICATION_ID_KEY } from 'src/app/shared/constants/teams.constants';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Prevent access to routes if access-token is not present.
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isTokenValid().then((validation) => {
      if (validation) {
        // check if dd logging is enabled
        if (environment.datadog.enableDatadogRum) {
          if (datadogRum.getUser()['id'] === undefined) {
            return this.authService.getUserInfo().then((user) => {
              datadogRum.setUser({
                id : user.attributes['sub'],
                name : user.attributes['name'],
                email : user.attributes['email']})
              return true
            }).catch((error) => {
              console.error('error',error)
              // even if it fails, we still want to return true since validation passed
              return true
            })
          }
        }
        return true;
      } else {
        this.authService.redirectToAuthPortal('sign_in', false);
        if (environment.datadog.enableDatadogRum) {
          datadogRum.clearUser();
        }
        return false;
      }
    });
  }
}
