import { Injectable } from '@angular/core';
import { FSToastModel } from './fs-toast.model';

@Injectable()
export class FsToastService {
  private _toasts: FSToastModel[];
  public get toasts(): FSToastModel[] {
    return this._toasts;
  }

  constructor() {
    this._toasts = new Array<FSToastModel>();
  }

  public async show(
    title: string,
    description: any,
    duration: number = 2000,
  ): Promise<void> {
    if (description instanceof ProgressEvent) {
      description = 'You are offline. Please check your internet connection.';
    }

    return new Promise((resolve) => {
      let id = this._toasts.length + 1;

      this._toasts.push(
        new FSToastModel({
          title,
          description,
          duration,
          id,
        }),
      );

      return setTimeout(() => {
        this.remove(id);
        resolve();
      }, duration);
    });
  }

  public remove(id: number): void {
    this._toasts = this._toasts.filter((f) => f.id !== id);
  }
}
