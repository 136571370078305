import {DefaultPrivacyLevel} from "@datadog/browser-rum";

enum Site {
  US1 = 'datadoghq.com'
}

type ConsoleApiName = 'log' | 'debug' | 'info' | 'warn' | 'error';

export const environment = {
  production: true,

  //Endpoints
  apiLeagueServer: 'https://stgcompeteapi.fullswingapps.com/api',
  apiBaymanagerServer: 'https://stglauncher.fullswingapps.com/api/fs',
  apiBaymanagerPanel: 'https://stgbaymanager.fullswingapps.com',
  //apiLeagueServer: 'https://localhost:44322/api',
  //apiBaymanagerServer: 'https://localhost:44361/api/fs',
  hostName: 'devbaymanager.fsgapi.net',

  //Variables
  defaultPage: 'home',
  navName: '_fs_nav_name',

  //Objects
  breakPointScreen: {
    small: 576,
    Medium: 768,
    large: 992,
    extraLarge: 1200,
    xExtraLarge: 1400,
  },

  //Ploicy groups
  companyAdmin: 'companyadmin',
  competeAdmin: 'competeadmin',
  bayManagerAdmin: 'baymanageradmin',
  competePlayer: 'competeplayer',
  adminPortal: 'techadmin',
  residential: 'Users',

  authPortalUrl: 'https://stgauth.fullswingapps.com/',

  datadog: {
    enableDatadogLogs: true,
    enableDatadogRum: true,
    rumConfig: {
      applicationId: '80d32f98-00e3-4658-93b2-a766bc69967e',
      clientToken: 'pub1180d7d9bf9a944718bf05ef0a7dc651',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: Site.US1,
      service: 'fs-compete-mobile-website',
      env: 'staging',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '1.1.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      allowedTracingUrls: ["https://stgcompeteapi.fullswingapps.com"]
    },
    // make the forwardConsoleLogs only to "warn" using the ConsoleApiName[] required


    logsConfig: {
      clientToken: 'pub1180d7d9bf9a944718bf05ef0a7dc651',
      site: Site.US1,
      service: 'fs-compete-mobile-website',
      env: 'staging',
      version: '1.1.0',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: [ 'warn', 'error'] as ConsoleApiName[],
      sessionSampleRate: 100,
    }
  }
};
