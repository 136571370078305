import {
  FSActionElement,
  InlinePosition,
} from '../../fs-controls-shared.model';

export class FSTableCell {
  classes?: string;
  value?: any;
  actions?: FSActionElement<any>[];
  inlinePosition?: InlinePosition;

  constructor(model: FSTableCell) {
    this.classes = model.classes || '';
    this.value = model.value;
    this.actions = model.actions || (null as any);
    this.inlinePosition = model.inlinePosition || InlinePosition.Start;
  }
}

export class FSTableHeaderCell extends FSTableCell {
  hasSorting?: boolean;
  width?: string;

  constructor(model: FSTableHeaderCell) {
    super(model);

    this.hasSorting = model.hasSorting || false;
    this.width = model.width || 'unset';
  }
}

export class FSTableHeader {
  hideHeader?: boolean;
  activeSortingAll?: boolean;
  cells?: FSTableHeaderCell[];

  constructor(model: FSTableHeader) {
    this.hideHeader = model.hideHeader || false;
    this.activeSortingAll = model.activeSortingAll || false;
    this.cells = model.cells || new Array<FSTableHeaderCell>();
  }
}

export class FSTableRecord {
  id?: any;
  cells?: FSTableCell[];
  classes?: string;

  constructor(model: FSTableRecord) {
    this.id = model.id || (null as any);
    this.cells = model.cells || new Array<FSTableCell>();
    this.classes = model.classes || '';
  }
}

export class FSTable {
  id?: number;
  classes?: string;
  header?: FSTableHeader;
  records?: FSTableRecord[];
  hasPagination?: boolean;
  isActive?: boolean;

  constructor(model: FSTable) {
    this.id = model.id || 0;
    this.classes = model.classes || '';
    this.header = model.header || new FSTableHeader({ hideHeader: true });
    this.records = model.records || new Array<FSTableRecord>();
    this.hasPagination = model.hasPagination || false;
    this.isActive = model.isActive || true;
  }
}
