import { DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './shared/services/http.service';
import { IndexedDBService } from './shared/services/IndexedDB.service';
import { NavigationComponent } from './components/navigation/navigation.component';
import { HelperService } from './shared/services/helper.service';
import { LoggerService } from './shared/services/logger.service';
import { AuthService } from './pages/auth/auth.service';
import { NavigationService } from './components/navigation/navigation.service';
import { FsPageFrameModule } from './shared/modules/fs-page-frame/fs-page-frame.module';
import { FSMiniLoaderModule } from './shared/modules/fs-mini-loader/fs-mini-loader.module';
import { FSMiniLoaderService } from './shared/modules/fs-mini-loader/fs-mini-loader.service';
import { GlobalService } from './shared/services/global.service';
import { AuthGuard } from './pages/auth/auth.guard';
import { HttpInterceptorProviders } from './shared/services/interceptor-provider';
import { FsToastModule } from './shared/modules/fs-popups/fs-toast/fs-toast.module';
import { FsImageModule } from './shared/modules/fs-controls/fs-image/fs-image.module';
import { FsOptionsDirective } from './shared/modules/fs-directives/fs-options.directive';
import { FsAlignmentDirectives } from './shared/modules/fs-directives/fs-alignment/alignment-import.directives';
import { FsTextOverflowDirective } from './shared/modules/fs-directives/fs-text-overflow.directive';
import { PageFrameService } from './shared/modules/fs-page-frame/fs-page-frame.service';
import {
  AUTH_INITIAL_ID_TOKEN_KEY,
  AUTH_REDIRECT_SEARCH_PARAMS,
} from './shared/constants/auth.constants';

@NgModule({
  declarations: [AppComponent, NavigationComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FsPageFrameModule,
    FSMiniLoaderModule,
    FsToastModule,
    FsImageModule,
    FsOptionsDirective,
    FsTextOverflowDirective,
    FsAlignmentDirectives,
  ],
  providers: [
    HttpInterceptorProviders,
    NavigationService,
    HelperService,
    HttpService,
    IndexedDBService,
    AuthService,
    FSMiniLoaderService,
    GlobalService,
    AuthGuard,
    PageFrameService,
    LoggerService,
  ],
  exports: [],
})
export class AppModule implements DoBootstrap {
  ngDoBootstrap(app: any) {
    const urlParams = new URLSearchParams(window.location.search);
    const initialIdToken = urlParams.get('id_token');
    if (initialIdToken) {
      window.localStorage.setItem(AUTH_INITIAL_ID_TOKEN_KEY, initialIdToken);
      const authRedirectSearchParams = window.localStorage.getItem(
        AUTH_REDIRECT_SEARCH_PARAMS,
      );
      window.history.pushState(
        {},
        document.title,
        `${window.location.pathname}${authRedirectSearchParams || ''}`,
      );
      window.localStorage.removeItem(AUTH_REDIRECT_SEARCH_PARAMS);
    }
    app.bootstrap(AppComponent);
  }
}
