import { HandicapRange } from '../static-objects';
import { BaseDTO } from './base-dto.model';
import { GetEventExternalResult } from './event.model';
import { PlayerLocationExternalResult } from './player-location.model';
import { RoundResult } from './round.model';
import {
  CreateSimulatorPreferenceRequest,
  SimulatorPreferenceResult,
  UpdateSimulatorPreferenceRequest,
} from './simulator-preference.model';

export class CreatePlayerRequest {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  cognitoSub?: string;
  handedness?: string;
  handicap?: number;
  gender?: string;
  profileImageUrl?: string;
  simulatorPreference?: CreateSimulatorPreferenceRequest;

  constructor(model: CreatePlayerRequest) {
    this.firstName = model.firstName?.length ? model.firstName.trim() : '';
    this.lastName = model.lastName?.length ? model.lastName.trim() : '';
    this.phoneNumber = model.phoneNumber?.length ? model.phoneNumber : null;
    this.email = model.email?.length ? model.email.trim() : '';
    this.cognitoSub = model.cognitoSub || '';
    this.handedness = model.handedness || 'None';
    this.handicap = model.handicap || HandicapRange.default;
    this.gender = model.gender || 'None';
    this.profileImageUrl = model.profileImageUrl || '';
    this.simulatorPreference = model.simulatorPreference || (null as any);
  }
}

export class UpdatePlayerRequest {
  id?: number;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  cognitoSub?: string;
  handedness?: string;
  handicap?: number;
  gender?: string;
  profileImageUrl?: string;
  simulatorPreference?: UpdateSimulatorPreferenceRequest;

  constructor(model: UpdatePlayerRequest) {
    this.id = model.id || 0;
    this.firstName = model.firstName?.length ? model.firstName.trim() : '';
    this.lastName = model.lastName?.length ? model.lastName.trim() : '';
    this.phoneNumber = model.phoneNumber?.length ? model.phoneNumber : null;
    this.email = model.email?.length ? model.email.trim() : '';
    this.cognitoSub = model.cognitoSub || '';
    this.handedness = model.handedness || 'None';
    this.handicap = model.handicap || HandicapRange.default;
    this.gender = model.gender || 'None';
    this.profileImageUrl = model.profileImageUrl || '';
    this.simulatorPreference = model.simulatorPreference || (null as any);
  }
}

export class PlayerResult extends BaseDTO {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  cognitoSub?: string;
  handedness?: string;
  handicap?: number;
  gender?: string;
  profileImageUrl?: string;
  simulatorPreference?: SimulatorPreferenceResult;
  events?: GetEventExternalResult[];
  playerLocations?: PlayerLocationExternalResult[];
  rounds?: RoundResult[];

  constructor(model: PlayerResult) {
    super(model);

    this.firstName = model.firstName || '';
    this.lastName = model.lastName || '';
    this.phoneNumber = model.phoneNumber || '';
    this.email = model.email || '';
    this.cognitoSub = model.cognitoSub || '';
    this.handedness = model.handedness || 'None';
    this.handicap = model.handicap || HandicapRange.default;
    this.gender = model.gender || 'None';
    this.profileImageUrl = model.profileImageUrl || '';
    this.simulatorPreference =
      model.simulatorPreference || new SimulatorPreferenceResult({});
    this.events = model.events || (null as any);
    this.playerLocations = model.playerLocations || (null as any);
    this.rounds = model.rounds || (null as any);
  }
}

export class GetExternalPlayerResult extends PlayerResult {
  //Custom fields
  asNotification?: boolean;

  constructor(model: GetExternalPlayerResult) {
    super(model);

    this.asNotification = model.asNotification || false;
  }
}

export class ChangePasswordObject {
  code?: string;
  username?: string;

  constructor(model: ChangePasswordObject) {
    this.code = model.code || '';
    this.username = model.username || '';
  }
}
