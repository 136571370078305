import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './pages/auth/auth.service';
import { FSMiniLoaderService } from './shared/modules/fs-mini-loader/fs-mini-loader.service';
import { GlobalService } from './shared/services/global.service';
import { convertUrlParamsToObject, sleep } from './shared/helper-functions';
import { AuthState } from './shared/enums/auth-state.enum';
import { ChangePasswordObject } from './shared/models/player.model';
import { FsToastService } from './shared/modules/fs-popups/fs-toast/fs-toast.service';
import { PageFrameService } from './shared/modules/fs-page-frame/fs-page-frame.service';
import { LoggerService } from './shared/services/logger.service';
import {
  ACCEPT_JOIN_TEAM_PAYLOAD_KEY,
  JOIN_TEAM_NOTIFICATION_ID_KEY,
} from './shared/constants/teams.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  notifId: number;

  constructor(
    public authService: AuthService,
    public loaderService: FSMiniLoaderService,
    private globalService: GlobalService,
    private toastService: FsToastService,
    public pageFrameService: PageFrameService,
    private loggerService: LoggerService,
    private router: Router,
  ) {
    this.notifId = 0;
    this.loggerService.printVersion();

    let queryArr = window.location.href.split('?');
    let queryObj = convertUrlParamsToObject(queryArr[1]) ?? 0;

    if (queryObj?.code?.length && queryObj?.username?.length) {
      this.authService.changePasswordUrlObject = new ChangePasswordObject({
        ...queryObj,
      });
    }
  }

  async ngOnInit(): Promise<void> {
    await this.checkUserAuth();
    window.onpageshow = (event: any) => {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }

  async checkUserAuth(): Promise<void> {
    try {
      this.loaderService.loader = true;
      this.authService.isAuthenticate = AuthState.False;
      const player = await this.authService.getUserData();
      if (!player) {
        return;
      }
      this.globalService.player = player;
      this.authService.isAuthenticate = AuthState.True;
      const teamJoinNotificationId = window.localStorage.getItem(
        JOIN_TEAM_NOTIFICATION_ID_KEY,
      );
      const acceptToJoinTeamPayload = window.localStorage.getItem(
        ACCEPT_JOIN_TEAM_PAYLOAD_KEY,
      );
      await sleep(200);
      if (teamJoinNotificationId) {
        await this.router.navigate(['teams', 'join'], {
          queryParams: { n: teamJoinNotificationId },
        });
        window.localStorage.removeItem(JOIN_TEAM_NOTIFICATION_ID_KEY);
      }
      if (acceptToJoinTeamPayload) {
        const payload = JSON.parse(acceptToJoinTeamPayload);
        await this.router.navigate(['teams', 'request-to-join'], {
          queryParams: {
            n: payload.notifId,
            t: payload.teamId,
            p: payload.playerId,
          },
        });
        window.localStorage.removeItem(ACCEPT_JOIN_TEAM_PAYLOAD_KEY);
      }
      return;
    } catch (error) {
      console.error(error);
      await this.authService.unauthorized([JOIN_TEAM_NOTIFICATION_ID_KEY]);
    } finally {
      this.loaderService.loader = false;
    }
  }
}
