import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';

import awsconfig from './aws-exports';
import { Amplify } from 'aws-amplify';
import { environment } from './environments/environment';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

Amplify.configure(awsconfig);

// Initialize Datadog Real User Monitoring
if (environment.datadog.enableDatadogRum) {

  datadogRum.init(environment.datadog.rumConfig);
}

// Initialize Datadog Logs
if (environment.datadog.enableDatadogLogs) {
  datadogLogs.init(environment.datadog.logsConfig);
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
