import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import {
  CognitoIdToken,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { lastValueFrom } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { ApiUrl } from 'src/app/shared/enums/api-url.enum';
import {
  ChangePasswordObject,
  PlayerResult,
} from 'src/app/shared/models/player.model';
import {
  ListResult,
  PageOptions,
} from 'src/app/shared/models/application.model';
import { IgnoreAuthenticateUrl } from 'src/app/shared/static-objects';
import { AuthState } from 'src/app/shared/enums/auth-state.enum';
import { removeStorageItemsByExclude } from 'src/app/shared/helper-functions';
import { LoggerService } from 'src/app/shared/services/logger.service';
import {
  AUTH_INITIAL_ID_TOKEN_KEY,
  AUTH_REDIRECT_SEARCH_PARAMS,
} from 'src/app/shared/constants/auth.constants';
import { isMobile } from 'src/app/shared/mobile';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isAuthenticate: AuthState;
  public get isAuthenticate(): AuthState {
    return this._isAuthenticate;
  }
  public set isAuthenticate(v: AuthState) {
    this._isAuthenticate = v;
  }

  private _changePasswordUrlObject: ChangePasswordObject = null;
  public get changePasswordUrlObject(): ChangePasswordObject {
    return this._changePasswordUrlObject;
  }
  public set changePasswordUrlObject(v: ChangePasswordObject) {
    this._changePasswordUrlObject = v;
  }

  constructor(
    private httpService: HttpService,
    private router: Router,
    private loggerService: LoggerService,
  ) {
    this._isAuthenticate = AuthState.False;
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (e) {
      return false;
    }
  }

  async isTokenValid(): Promise<boolean> {
    const initialIdToken = window.localStorage.getItem(
      AUTH_INITIAL_ID_TOKEN_KEY,
    );
    if (initialIdToken) {
      const jwtPayload = this.parseJWT(initialIdToken);
      return jwtPayload.exp * 1000 > new Date().getTime();
    }
    try {
      return (await Auth.currentSession()).isValid();
    } catch (err) {
      return false;
    }
  }

  getInitialIdToken() {
    return window.localStorage.getItem(AUTH_INITIAL_ID_TOKEN_KEY);
  }

  async refreshToken(): Promise<boolean> {
    try {
      const user = await this.getCurrentUser();
      const idToken = user.getSignInUserSession().getIdToken();
      user.refreshSession(
        user.getSignInUserSession().getRefreshToken(),
        this.tokenRefreshed,
      );
      return true;
    } catch (err) {
      return false;
    }
  }

  async getTokenExpiration(): Promise<Date> {
    try {
      const user = await this.getCurrentUser();
      const unix_timestamp = user
        .getSignInUserSession()
        .getIdToken()
        .getExpiration();
      // Create a new JavaScript Date object based on the timestamp
      // multiplied by 1000 so that the argument is in milliseconds, not seconds.
      return new Date(unix_timestamp * 1000);
    } catch (err) {
      return null;
    }
  }

  async getUserId(): Promise<string> {
    try {
      const attr = await this.getUserInfo();
      return attr.username;
    } catch (err) {
      //console.log(err);
      return null;
    }
  }

  async getIdToken(): Promise<CognitoIdToken> {
    try {
      const user = await this.getCurrentUser();
      return user.getSignInUserSession().getIdToken();
    } catch (err) {
      return null;
    }
  }

  async getCurrentSession(): Promise<CognitoUserSession> {
    return await Auth.currentSession();
  }

  async getCurrentUser(): Promise<CognitoUser> {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (err) {
      return null;
    }
  }

  async getUserInfo(): Promise<any> {
    try {
      return await Auth.currentUserInfo();
    } catch (err) {
      //console.log('Current user info failed to fetch', err);
      return err;
    }
  }

  async getUserAttributebyKey(key: string): Promise<string> {
    try {
      let currentUserInfo = await Auth.currentUserInfo();
      return currentUserInfo.attributes[key];
    } catch (err) {
      console.error('error fetching user info: ', err);
      return null;
    }
  }

  async signIn(userName: string, password: string): Promise<any> {
    try {
      const user = await Auth.signIn(userName, password);

      if (
        user.challengeName &&
        user.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        return -1;
      } else if (user && user.attributes && user.attributes.sub?.length) {
        // user exists
        return 1;
      } else {
        // user doenst exist
        return -2;
      }
    } catch (err) {
      console.error('Current user info failed to fetch', err);
      return 0;
    }
  }

  async completeNewPassword(userName: string, code: string, password: string) {
    return Auth.signIn(userName, code)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          return Auth.completeNewPassword(
            user, // the Cognito User Object
            password, // the new password
          )
            .then((res) => {
              return true;
            })
            .catch((e) => {
              console.error(e);
              return false;
            });
        } else {
          // at this time the user is logged in if no MFA required

          //console.log(user);
          return true;
          // other situations
        }
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  }

  async changePassword(oldPassword: string, newPassword: string) {
    try {
      return await Auth.changePassword(
        await this.getCurrentUser(),
        oldPassword,
        newPassword,
      );
    } catch (err) {
      //console.log('Current user info failed to fetch', err);
      return err;
    }
  }

  async forgotPassword(userName: string) {
    try {
      return await Auth.forgotPassword(userName);
    } catch (err) {
      //console.log('Current user info failed to fetch', err);
      return err;
    }
  }

  async forgotPasswordSubmit(
    userName: string,
    confirmationCode: string,
    password: string,
  ) {
    try {
      return await Auth.forgotPasswordSubmit(
        userName,
        confirmationCode,
        password,
      );
    } catch (err) {
      //console.log('Current user info failed to fetch', err);
      return err;
    }
  }

  async signOut(): Promise<void> {
    try {
      await Auth.signOut({ global: false });
    } catch (error) {
      console.error(error);
    }
  }

  public async getUserData(): Promise<PlayerResult> {
    try {
      const players = await lastValueFrom(
        this.httpService.getItemByQuery<ListResult<PlayerResult>>(
          ApiUrl.FromLeague,
          '/player-manager',
          {
            ...new PageOptions({}),
          },
        ),
      );

      if (players.data?.length === 1) {
        this.loggerService.printObject('Player Data', players.data[0]);
        return players.data[0];
      } else return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public isToIgnoreAuthPage(): boolean {
    let url = window.location.pathname;
    let ignoreUrls = IgnoreAuthenticateUrl;

    for (let index = 0; index < ignoreUrls.length; index++) {
      if (ignoreUrls[index].search(url) > -1) {
        return true;
      }
    }

    return false;
  }

  private tokenRefreshed(e, r) {
    const idToken = r.getIdToken();
  }

  public async isCompetePlayer(): Promise<boolean> {
    try {
      const userSession = await this.getCurrentSession();
      const groups: Array<string> =
        userSession.getAccessToken().payload['cognito:groups'];

      for (let index = 0; index < groups.length; index++) {
        if (groups[index] === 'competeplayer') return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async isAdmin(admin: string = null): Promise<boolean> {
    try {
      const userSession = await this.getCurrentSession();
      const groups: Array<string> =
        userSession.getAccessToken().payload['cognito:groups'];

      if (admin?.length) return !!groups.find((f) => f === admin);

      return groups.filter((f) => f.search('admin') > 0)?.length > 0;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async getCognitoGroups(): Promise<Array<string>> {
    try {
      const userSession = await this.getCurrentSession();
      return userSession.getAccessToken()?.payload['cognito:groups'];
    } catch (error) {
      return null;
    }
  }

  async isAuthenticatedCognitoGroups(): Promise<boolean> {
    return true;
  }

  public async unauthorized(excludeStorageKeys?: string[]) {
    const initialIdToken = this.getInitialIdToken();
    if (initialIdToken) {
      this._isAuthenticate = AuthState.UseAuth;
      removeStorageItemsByExclude(excludeStorageKeys);
      this.redirectToAuthPortal('sign_out', true);
      return;
    }
    try {
      await this.signOut();
    } catch (error) {
      console.error(error);
    } finally {
      this._isAuthenticate = AuthState.UseAuth;
      removeStorageItemsByExclude(excludeStorageKeys);
      if (isMobile()) {
        this.redirectToAuthPortal('sign_out', true);
      } else {
        await this.router.navigate(['login']);
      }
    }
  }

  private parseJWT(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  }

  public redirectToAuthPortal(action?: string, redirectToHome?: boolean) {
    let redirectUrl = location.href;
    let redirectUrlSearchParams = location.search;
    if (redirectUrlSearchParams) {
      redirectUrl = redirectUrl.replace(redirectUrlSearchParams, '');
      localStorage.setItem(
        AUTH_REDIRECT_SEARCH_PARAMS,
        redirectUrlSearchParams,
      );
    }
    if (redirectToHome) {
      redirectUrl = `${location.protocol}//${location.host}/home`;
    }
    location.href = `${environment.authPortalUrl}?redirect_url=${encodeURIComponent(redirectUrl)}&action=${action}`;
  }
}
