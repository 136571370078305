import { Injectable, TemplateRef } from '@angular/core';
import { FSPageHistoryOption, PageHistoryType } from './fs-page-frame.model';
import { Subject, from, last } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PageFrameService {
  //Use frame wrapper
  private _useFrameWrapper: boolean;
  public get useFrameWrapper(): boolean {
    return this._useFrameWrapper;
  }
  public set useFrameWrapper(v: boolean) {
    this._useFrameWrapper = v;
  }

  //Frame background opacity
  private _backgroundOpacity: number;
  public get backgroundOpacity(): number {
    return this._backgroundOpacity;
  }
  public set backgroundOpacity(v: number) {
    this._backgroundOpacity = v;
  }

  //Header utils template
  private _startSlotTemplate: TemplateRef<any>;
  public get startSlotTemplate(): TemplateRef<any> {
    return this._startSlotTemplate;
  }
  public set startSlotTemplate(v: TemplateRef<any>) {
    this._startSlotTemplate = v;
  }

  private _endSlotTemplate: TemplateRef<any>;
  public get endSlotTemplate(): TemplateRef<any> {
    return this._endSlotTemplate;
  }
  public set endSlotTemplate(v: TemplateRef<any>) {
    this._endSlotTemplate = v;
  }

  private _topSlotTemplate: TemplateRef<any>;
  public get topSlotTemplate(): TemplateRef<any> {
    return this._topSlotTemplate;
  }
  public set topSlotTemplate(v: TemplateRef<any>) {
    this._topSlotTemplate = v;
  }

  //Show frame background
  private _backgroundVisible: boolean;
  public get backgroundVisible(): boolean {
    return this._backgroundVisible;
  }
  public set backgroundVisible(v: boolean) {
    this._backgroundVisible = v;
  }

  //Search section
  private _isActiveSearchBox: boolean;
  public get isActiveSearchBox(): boolean {
    return this._isActiveSearchBox;
  }
  public set isActiveSearchBox(v: boolean) {
    this._isActiveSearchBox = v;
  }

  private searchSubscribe = new Subject<string>();
  public searchSubscribe$ = this.searchSubscribe.asObservable();

  public setSearchInput(value: string): void {
    this.searchSubscribe.next(value);
  }

  //Main background image
  private _backgroundClass: string;
  public get backgroundClass(): string {
    return this._backgroundClass;
  }
  public set backgroundClass(v: string) {
    this._backgroundClass = v;
  }

  //History section
  private _isActiveHistoryState: boolean;
  public get isActiveHistoryState(): boolean {
    return this._isActiveHistoryState;
  }
  public set isActiveHistoryState(v: boolean) {
    this._isActiveHistoryState = v;
  }

  private _pageHistory: FSPageHistoryOption[];
  public get pageHistory(): FSPageHistoryOption[] {
    return this._pageHistory;
  }

  private _historyIndex: number;
  public get historyIndex(): number {
    return this._historyIndex;
  }

  private pageSubscribe = new Subject<FSPageHistoryOption>();
  public pageSubscribe$ = this.pageSubscribe.asObservable();

  private saveHistorySubscribe = new Subject<FSPageHistoryOption>();
  public saveHistorySubscribe$ = this.saveHistorySubscribe.asObservable();

  constructor(private router: Router) {
    this.initialHistories();
    this._isActiveHistoryState = this._isActiveSearchBox = false;
    this._backgroundVisible = true;
    this._backgroundClass = 'main-background-1';
    this._backgroundOpacity = 0.5;
    this._useFrameWrapper = true;
  }

  private initialHistories(): void {
    let histories = window.localStorage.getItem('_fs_cp_page_his');

    if (histories?.length) {
      this._pageHistory = JSON.parse(histories) as Array<FSPageHistoryOption>;
      this._historyIndex = this._pageHistory.length;
    } else {
      this._pageHistory = new Array<FSPageHistoryOption>();
      this._historyIndex = 0;
    }
  }

  public getCurrentHistory(): FSPageHistoryOption {
    return this._pageHistory[
      this._historyIndex === 0 ? 0 : this._historyIndex - 1
    ];
  }

  public popHistory(): void {
    if (this._pageHistory?.length > 1) {
      this._pageHistory.pop();
      this._historyIndex--;

      window.localStorage.setItem(
        '_fs_cp_page_his',
        JSON.stringify(this._pageHistory),
      );

      let currentHistory = this._pageHistory[this._historyIndex - 1];

      if (currentHistory) {
        this.router.navigateByUrl(currentHistory.route).then((_) => {
          if (currentHistory.type !== PageHistoryType.ChangePage)
            this.pageSubscribe.next(currentHistory);
        });
      }
    }
  }

  public saveHistory(history: FSPageHistoryOption): void {
    if (
      this._pageHistory?.length &&
      ((history.type === PageHistoryType.ChangePage &&
        history.route === this._pageHistory[this._historyIndex - 1].route) ||
        (history.type === PageHistoryType.ChangeTab &&
          history.id === this._pageHistory[this._historyIndex - 1].id))
    )
      return;

    this._pageHistory.push(history);
    this._historyIndex++;
    window.localStorage.setItem(
      '_fs_cp_page_his',
      JSON.stringify(this._pageHistory),
    );

    //Clear slots
    this.startSlotTemplate = this.endSlotTemplate = null as any;

    this.saveHistorySubscribe.next(history);
  }

  public appendFragmentToLastHistory(fragment: string): void {
    from(this._pageHistory)
      .pipe(last())
      .subscribe((res) => (res.route = `${res.route}#${fragment}`));
  }

  public resetHistory(): void {
    // if (this._pageHistory?.length) {
    //   this._historyIndex = 0;
    //   let tmp = this._pageHistory[0];
    //   this._pageHistory = new Array<FSPageFrameOption>();
    //   this.saveHistory(tmp);
    // }

    this._historyIndex = 0;
    this._pageHistory = new Array<FSPageHistoryOption>();
    window.localStorage.removeItem('_fs_cp_page_his');
  }

  public existHistory(): boolean {
    return (
      this._pageHistory?.length &&
      this._pageHistory[0].type !== PageHistoryType.None
    );
  }

  public getLatestTabActive(): FSPageHistoryOption {
    let tabs = this._pageHistory?.filter(
      (f) => f.type === PageHistoryType.ChangeTab,
    );

    if (tabs?.length) {
      return tabs[tabs.length - 1];
    } else {
      return null;
    }
  }
  //------------------------------------
}
