<main>
    <div class="main"  [class.main-mobile]="true" [class]="pageFrameService.backgroundClass">
        <div class="container container--mobile">
            <fs-page-frame>
                <router-outlet></router-outlet>
            </fs-page-frame>
        </div>
    </div>
</main>

<fs-mini-loader></fs-mini-loader>
<fs-toast></fs-toast>
